import * as React from 'react'
const Context = React.createContext()

const initialState = {
  error: false,
  config: {},
  token: '',
  userInfo: {
    accountCreation: {},
    forgot_email: '',
    terms_accepted: [],
    profile: {
      // first_name: 'Sally',
      // last_name: 'Woods',
      // health_card_number: '123456',
      // driver_license_number: '123456',
      // voluntery_number: '123456',
      // expiry_date: '2024/10',
      // expiry_full_date: '2024/10/12',
      // serial: '123456',
      // date_of_birth: '1963-01-02',
      // consent: 1,
      // ial_level: 'expired', '1', '3'
      // verification_status: false
    },
  },
}

const ACTION_TYPES = {
  SET_FROM_LOCAL: 'setFromLocal',
  UPDATE_ERROR: 'updateError',
  UPDATE_CONFIG: 'updateConfig',
  UPDATE_TOKEN: 'updateToken',
  UPDATE_USERINFO_INFO_UPDATE: 'updateInfoUpdate',
  UPDATE_USERINFO_PROFILE: 'updateProfile',
  UPDATE_USERINFO_FORGOT_EMAIL: 'updateForgotEmail',
  UPDATE_USERINFO_LOGGED_IN_WITH: 'updateLoggedInWith',
  UPDATE_USERINFO_RETURN_URL: 'updateReturnUrl',
  UPDATE_USERINFO_PENDING_EMAIL: 'updatePendingEmail',
  UPDATE_USERINFO_FORGOT_PASSWORD_EMAIL: 'updateForgotPasswordEmail',
  UPDATE_USERINFO_TERMS_ACCEPTED: 'updateTermsAccepted',
  UPDATE_PREVIOUS: 'updatePrevious',
  UPDATE_ACCOUNT_CREATION: 'updateAccountCreation',
}

function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_FROM_LOCAL: {
      return { ...state, ...action.payload }
    }
    case ACTION_TYPES.UPDATE_CONFIG: {
      return { ...state, config: action.payload }
    }
    case ACTION_TYPES.UPDATE_ERROR: {
      // use this for timeout
      return { ...state, userInfo: { ...state.userInfo, timeout: action.payload } }
    }
    case ACTION_TYPES.UPDATE_TOKEN: {
      return { ...state, token: action.payload }
    }
    case ACTION_TYPES.UPDATE_USERINFO_INFO_UPDATE: {
      return { ...state, userInfo: { ...state.userInfo, info_update: action.payload } }
    }
    case ACTION_TYPES.UPDATE_USERINFO_PROFILE: {
      return { ...state, userInfo: { ...state.userInfo, profile: action.payload } }
    }
    case ACTION_TYPES.UPDATE_USERINFO_FORGOT_EMAIL: {
      return { ...state, userInfo: { ...state.userInfo, forgot_email: action.payload } }
    }
    case ACTION_TYPES.UPDATE_USERINFO_LOGGED_IN_WITH: {
      return { ...state, userInfo: { ...state.userInfo, logged_in_with: action.payload } }
    }
    case ACTION_TYPES.UPDATE_USERINFO_TERMS_ACCEPTED: {
      return { ...state, userInfo: { ...state.userInfo, terms_accepted: action.payload } }
    }
    case ACTION_TYPES.UPDATE_USERINFO_RETURN_URL: {
      return { ...state, userInfo: { ...state.userInfo, return_url: action.payload } }
    }
    case ACTION_TYPES.UPDATE_USERINFO_PENDING_EMAIL: {
      return { ...state, userInfo: { ...state.userInfo, pending_email: action.payload } }
    }
    case ACTION_TYPES.UPDATE_PREVIOUS: {
      return { ...state, previous: action.payload }
    }
    case ACTION_TYPES.UPDATE_ACCOUNT_CREATION: {
      return { ...state, userInfo: { ...state.userInfo, accountCreation: action.payload } }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

// action creators
export const setFromLocal = (state) => ({ type: ACTION_TYPES.SET_FROM_LOCAL, payload: state })
export const updateError = (error) => ({ type: ACTION_TYPES.UPDATE_ERROR, payload: error })
export const updateConfig = (state) => ({ type: ACTION_TYPES.UPDATE_CONFIG, payload: state })
export const updateToken = (state) => ({ type: ACTION_TYPES.UPDATE_TOKEN, payload: state })
export const updateInfoUpdate = (state) => ({ type: ACTION_TYPES.UPDATE_USERINFO_INFO_UPDATE, payload: state })
export const updateProfileState = (state) => ({ type: ACTION_TYPES.UPDATE_USERINFO_PROFILE, payload: state })
export const updateForgotEmail = (state) => ({ type: ACTION_TYPES.UPDATE_USERINFO_FORGOT_EMAIL, payload: state })
export const updateLoggedInWith = (state) => ({ type: ACTION_TYPES.UPDATE_USERINFO_LOGGED_IN_WITH, payload: state })
export const updateTermsAccepted = (state) => ({ type: ACTION_TYPES.UPDATE_USERINFO_TERMS_ACCEPTED, payload: state })
export const updateReturnUrl = (state) => ({ type: ACTION_TYPES.UPDATE_USERINFO_RETURN_URL, payload: state })
export const updatePendingEmail = (state) => ({ type: ACTION_TYPES.UPDATE_USERINFO_PENDING_EMAIL, payload: state })
export const updatePrevious = (state) => ({ type: ACTION_TYPES.UPDATE_PREVIOUS, payload: state })
export const updateAccountCreation = (state) => ({ type: ACTION_TYPES.UPDATE_ACCOUNT_CREATION, payload: state })

function ContextProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const key = 'iaa-pei'
  React.useEffect(() => {
    // get localstorage
    let item = window.localStorage.getItem(key) ? JSON.parse(window.localStorage.getItem(key)) : initialState
    item.config = window.config
    dispatch(setFromLocal(item))
  }, [])

  // when state changes, update localstorage
  React.useEffect(() => {
    const {
      error,
      token,
      userInfo: { terms_accepted },
    } = state
    const updateState = { error, config: window.config, token, userInfo: { profile: {}, terms_accepted } }
    window.localStorage.setItem(key, JSON.stringify(updateState))
  }, [state])

  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
}

function useContext() {
  return React.useContext(Context)
}

export { ContextProvider, useContext, reducer }
