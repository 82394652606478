import { useEffect } from 'react'

import { PageI } from '../../components/ComponentInterface'

import useHandleLogout from '../../common/hooks/useHandleLogout'

const LogoutPage = ({ otherRoutes: { loggingOut } }: PageI) => {
  const { handleLogout } = useHandleLogout()

  useEffect(() => {
    const getLogout = async () => {
      await handleLogout()
      return loggingOut()
    }

    getLogout()
    // eslint-disable-next-line
  }, [])
  return null
}

export default LogoutPage
