import { useEffect, useState } from 'react'

import { useWalletFlowHelper } from '../../../../../context/wallet/WalletFlowHelper'
import { RequestedResource } from '../../../../../wallet/types/transaction'
import { useContext } from '../../../../../Context'

const hashString = async (input: string): Promise<string> => {
  const encoder = new TextEncoder()
  const data = encoder.encode(input)
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('')
  return hashHex
}

const useScopesFromHash = (transaction_id: string) => {
  const [html, setHtml] = useState({ title: '', body: '', terms: '' })

  const {
    ConsentRequestTokenHistory: { get },
  } = useWalletFlowHelper()

  const {
    state: {
      config: { enrolled_clients },
    },
  } = useContext()

  useEffect(() => {
    const { client, requested_resources } = get(transaction_id)
    if (!client || !requested_resources) return

    const sortAndFilterResources = (resources: RequestedResource[]) => {
      const sortedResources = Object.fromEntries(
        resources
          .map((resource): [string, string[]] => [resource.resource_definition.res_def_id, resource.scopes_requested.sort().map((scope) => scope.scope)])
          .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)),
      )

      console.log(sortedResources)
    }

    const handleHashResources = async () => {
      const sortedRequestedResources = requested_resources && sortAndFilterResources(requested_resources)

      const key = await hashString(JSON.stringify(sortedRequestedResources))

      console.log(key)

      if (enrolled_clients[client.identifier].consent_ui?.[key]?.['en']) console.log('Consent UI Fallback for ' + key)

      setHtml(enrolled_clients[client.identifier].consent_ui?.[key]?.['en'])
    }
    handleHashResources()
  }, [enrolled_clients, get, transaction_id])

  return { html }
}

export default useScopesFromHash
