import { useContext } from '../../Context'
import { useTriggeredLogout } from '../../services'
import useSessionValidation from './useSessionValidation'

export default function useHandleLogout() {
  const { useLogout: logoutRequest } = useTriggeredLogout()
  const { cleanUpUserSessionInfo } = useSessionValidation()

  const {
    state: {
      config: { pei_portal_link },
    },
  } = useContext()

  const handleLogout = async () => {
    await logoutRequest()
    cleanUpUserSessionInfo()
  }

  const logoutMyPei = () => {
    const logoutUrl = `${pei_portal_link}?logout=true`
    window.location.href = logoutUrl
  }

  return { handleLogout, logoutMyPei }
}
